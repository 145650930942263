<template>
  <v-row align="center" justify="center">
    <v-btn-toggle color="primary" class="ma-2" rounded mandatory>
      <v-btn
        class="pa-4"
        elevation="0"
        @click="$emit('selectedLogin', 'email')"
      >
        <v-icon class="me-3">mdi-email</v-icon>E-Mail
      </v-btn>
      <v-btn
        elevation="0"
        class="pa-4"
        @click="$emit('selectedLogin', 'mobile')"
      >
        <v-icon class="me-3">mdi-cellphone-text</v-icon>Mobile
      </v-btn>
    </v-btn-toggle>
  </v-row>
</template>

<script>
export default {};
</script>

<style></style>
