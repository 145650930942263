export const returnuserTypes = () => {
  const user_types = [
    {
      txt: "Individual",
      val: "IND",
    },
    {
      txt: "Business",
      val: "BUS",
    },
  ];
  return user_types;
};
